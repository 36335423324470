import { RootState } from '@/interfaces/StoreStateInterfaces';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import authStore, { AUTH_STORE_NAME } from './auth.store';
import applicationStore, { APPLICATION_STORE_NAME } from './application.store';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

export const enum StoreActions {
  CLEAR_ALL_STORES = 'CLEAR_ALL_STORES'
}

// Persists auth and application module in local storage:
const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  key: process.env.VUE_APP_VUEX_KEY,
  modules: [AUTH_STORE_NAME]
});

const store: StoreOptions<RootState> = {
  modules: {
    [APPLICATION_STORE_NAME]: {
      namespaced: true,
      ...applicationStore,
    },
    [AUTH_STORE_NAME]: {
      namespaced: true,
      ...authStore,
    },
  },
  plugins: [vuexLocal.plugin],
  actions: {
    [StoreActions.CLEAR_ALL_STORES]: ({ commit }) => {
      Object.keys(store.modules!)
        .forEach((key: string) => commit(`${key}/CLEAR_STORE`));
    }
  }
};

export default new Vuex.Store<RootState>(store);

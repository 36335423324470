import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import CustomNotifications from '@/plugins/custom-notifications/CustomNotifications.plugin';
import '@/styles/main.scss';
import ErrorHandlerPlugin from './plugins/ErrorHandler.plugin';
import FormattingPlugin from '@/plugins/Formatting.plugin';
import AuthorizationPlugin from './plugins/Authorization.plugin';

// @ts-ignore
import VueMask from 'v-mask';

Vue.config.productionTip = false;

// Register custom plugins:
Vue.use(CustomNotifications);
Vue.use(ErrorHandlerPlugin);
Vue.use(FormattingPlugin);
Vue.use(AuthorizationPlugin);
Vue.use(VueMask);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

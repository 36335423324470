import Parseable from '@/misc/Parseable';

export default class Address extends Parseable {
    public id!: string;
    public city!: string;
    public country!: string;
    public houseNr!: string;
    public street!: string;
    public zip!: string;

    public static parseFromObject(object: any): Address {
        const company = new Address();
        Object.assign(company, object);
        return company;
    }

    public parseToObject(): Partial<Address> {
        return { ...this };
    }

    public get fullAddress() {
        return `${this.street} ${this.houseNr}, \n\n ${this.zip} ${this.city}`;
    }
}

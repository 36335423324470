/**
 * gn views
 */
export const ROUTE_LOGIN = 'login';
export const ROUTE_LOG_OUT = 'logout';
export const ROUTE_REGISTER = 'register';
export const ROUTE_HOME = 'home';
export const ROUTE_INITIATE_RESET_PASSWORD = 'initiate-reset-password';
export const ROUTE_PASSWORD_REQUESTED = 'password-requested';
export const ROUTE_WELCOME_REQUESTED = 'welcome-requested';
export const ROUTE_RESEND_WELCOME_MAIL = 'new-welcome-mail';
export const ROUTE_USER_INFORMATION = 'user-information';
export const ROUTE_USER_CREATED = 'user-created';
export const ROUTE_RESET_PASSWORD = 'reset-password';
export const ROUTE_CONFIRM_MAIL_CHANGED = 'confirm-mail-changed';
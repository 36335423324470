import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/src/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de'
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // Colors
        primary: '#005089',
        background: '#ffffff',
        inputGrey: '#E5E5E5',
        success: '#0BBD58',
        success2: '#B8E0C9',
        success3: '#EAF6EF',
        borderColor: '#E0E0E0',
        passwordHint: '#4c4c4c',
        scrollbar: '#d5d7dd',
      },
    },
  },
});

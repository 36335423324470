import ApiError from '@/api/misc/ApiError';
import axios from 'axios';

const ErrorHandlerPlugin = {
  install(Vue: any, options: any = {}) {
    const handleError = (error: any, customHandler: (() => any) | undefined = undefined) => {
      if (error instanceof ApiError) { // Handling of exceptions that were thrown in response interceptors
        if (customHandler) {
          customHandler();
        } else {
          Vue.notifyError('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
        }
      } else if (!axios.isAxiosError(error)) { // Axios errors are already handled in response interceptors
        Vue.notifyError('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
      }
      console.error(error);
    };
    Vue.prototype.$handleError = handleError;
    Vue.handleError = handleError;
  }
};

export default ErrorHandlerPlugin;
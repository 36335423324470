import { RootState, ApplicationState } from '@/interfaces/StoreStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

export const APPLICATION_STORE_NAME = 'application';

export const enum ApplicationStoreActions {
  GET_CURRENT_COMPANY = 'GET_CURRENT_COMPANY'
}

export const enum ApplicationStoreMutations {
  SET_COMPANY = 'SET_COMPANY',
  CLEAR_STORE = 'CLEAR_STORE'
}

export const enum ApplicationStoreGetters {
  CURRENT_COMPANY = 'CURRENT_COMPANY'
}

function initialApplicationState(): ApplicationState {
  return {
    application: null
  };
}

const store: ApplicationState = initialApplicationState();

const actions: ActionTree<ApplicationState, RootState> = {

};

const mutations: MutationTree<ApplicationState> = {
  [ApplicationStoreMutations.CLEAR_STORE]: (state: ApplicationState) => {
    // Merge rather than replace so we don't lose observers
    // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
    Object.assign(state, initialApplicationState());
  },
};

const getters: GetterTree<ApplicationState, RootState> = {

};

const applicationStore: Module<ApplicationState, RootState> = {
  state: store,
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default applicationStore;

import AuthRepository from '@/api/repositories/AuthRepository';
import {AuthState, RootState} from '@/interfaces/StoreStateInterfaces';
import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import User from "@/models/User.model";
import UserRepository from "@/api/repositories/UserRepository";
import {Gender} from "@/enum/UserTitle.enum";

export const AUTH_STORE_NAME = 'auth';

export const enum AuthStoreActions {
  LOGIN = 'LOGIN',
  LOGIN_WITH_TOKEN = 'LOGIN_WITH_TOKEN',
  INITIATE_PASSWORD_RESET = 'INITIATE_PASSWORD_RESET',
  RESET_PASSWORD = 'RESET_PASSWORD',
  REGISTER_RESTAURATEUR = 'REGISTER_RESTAURATEUR',
  UPDATE_USER = 'UPDATE_USER',
  GET_USER = 'GET_USER'
}

export const enum AuthStoreMutations {
  SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN',
  SET_TOKEN = 'SET_TOKEN',
  SET_USER = 'SET_USER',
  CLEAR_STORE = 'CLEAR_STORE',
}

export const enum AuthStoreGetters {
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  TOKEN = 'TOKEN',
  CURRENT_USER = 'CURRENT_USER',
}

function initialAuthState(): AuthState {
  return {
    refreshToken: undefined,
    token: undefined,
    currentUser: undefined,
  };
}

const store: AuthState = initialAuthState();

const actions: ActionTree<AuthState, RootState> = {
  [AuthStoreActions.LOGIN]: async ({ commit }, payload: { email: string, password: string })
      : Promise< User | null> => {
    const tokens = await AuthRepository.login(payload);

    // saves tokens to store
    commit(AuthStoreMutations.SET_TOKEN, tokens.data.access_token);
    commit(AuthStoreMutations.SET_REFRESH_TOKEN, tokens.data.refresh_token);

    const user = tokens.data.user;
    commit(AuthStoreMutations.SET_USER, user);
    return user;
  },
  [AuthStoreActions.LOGIN_WITH_TOKEN]: async ({ commit }, payload: { token: string, refreshToken: string })
      : Promise< User | null> => {
    // saves tokens to store
    commit(AuthStoreMutations.SET_TOKEN, payload.token);
    commit(AuthStoreMutations.SET_REFRESH_TOKEN, payload.refreshToken);
    return null;
  },
  [AuthStoreActions.INITIATE_PASSWORD_RESET]: async ({ commit }, email: string): Promise<any> => {
    const response = await AuthRepository.initiatePasswordReset(email);
    return response.data;
  },
  [AuthStoreActions.RESET_PASSWORD]: async ({ commit }, payload: { token: string, password: string }): Promise<any> => {
    const response = await AuthRepository.resetPassword(payload.token, payload.password);
    return response.data;
  },
  [AuthStoreActions.GET_USER]: async ({ commit }, id: string): Promise<any> => {
    const response = await UserRepository.getUserById(id);
    const user = User.parseFromObject(response.data);
    commit(AuthStoreMutations.SET_USER, user);
    return user;
  },
  [AuthStoreActions.UPDATE_USER]: async ({ commit }, payload: { id: string, user: User }): Promise<any> => {
    const response = await UserRepository.updateUser(payload.id, payload.user);
    const updatedUser = User.parseFromObject(response.data);
    commit(AuthStoreMutations.SET_USER, updatedUser);
    return updatedUser;
  },
};

const mutations: MutationTree<AuthState> = {
  [AuthStoreMutations.SET_TOKEN]: (state: AuthState, value: string | undefined) => {
    state.token = value;
  },
  [AuthStoreMutations.SET_REFRESH_TOKEN]: (state: AuthState, value: string | undefined) => {
    state.refreshToken = value;
  },
  [AuthStoreMutations.SET_USER]: (state: AuthState, value: User) => {
    state.currentUser = value;
  },
  [AuthStoreMutations.CLEAR_STORE]: (state: AuthState) => {
    // Merge rather than replace so we don't lose observers
    // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
    Object.assign(state, initialAuthState());
  }
};

const getters: GetterTree<AuthState, RootState> = {
  [AuthStoreGetters.TOKEN]: (state: AuthState) => state.token,
  [AuthStoreGetters.REFRESH_TOKEN]: (state: AuthState) => state.refreshToken,
  [AuthStoreGetters.CURRENT_USER]: (state: AuthState) => {
    return state.currentUser;
  },
};

const authStore: Module<AuthState, RootState> = {
  state: store,
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default authStore;

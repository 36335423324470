























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import {ROUTE_LOG_OUT, ROUTE_LOGIN} from "@/router/routes";
import store, {StoreActions} from "@/store";
import {AuthStoreGetters} from "@/store/auth.store";

@Component
export default class App extends Vue {
  private imprintURL: string = `${process.env.VUE_APP_API_URL}/files/imprint.html`;
  private dataPrivacyURL: string = `${process.env.VUE_APP_API_URL}/files/data-privacy.html`;
  private termsAndConditionsURL: string = `${process.env.VUE_APP_API_URL}/files/terms-and-conditions.html`;

  /**
   * checks if the user is logged in, used for showing the logout button if the user is logged in
   * @private
   */
  private get loggedIn() : boolean{
    return store.getters[`auth/${AuthStoreGetters.TOKEN}`];
  }

  /**
   * is called when user clicks on the logout button, clears the stores and pushes to the login view
   * @private
   */
  private async onLogoutPressed() {
    await this.$store.dispatch(StoreActions.CLEAR_ALL_STORES);
    await this.$router.push({name: ROUTE_LOG_OUT});
  }
}

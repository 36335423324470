import { AccountType } from '@/enum/AccountType.enum';
import Account from '@/models/Account';
import store from '@/store';
import { AuthStoreGetters, AUTH_STORE_NAME } from '@/store/auth.store';

const AuthorizationPlugin = {
  install(Vue: any, options: any = {}) {
    const isLoggedIn = (): boolean => {
      const token = store.getters[`${AUTH_STORE_NAME}/${AuthStoreGetters.TOKEN}`];
      return token != undefined;
    };
    Vue.prototype.$isLoggedIn = isLoggedIn;
    Vue.isLoggedIn = isLoggedIn;
  }
};

export default AuthorizationPlugin;

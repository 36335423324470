import axios, { AxiosInstance } from 'axios';
import Vue from 'vue';
import ApiError from './ApiError';

const baseURL: string = process.env.VUE_APP_API_URL!;
const axiosClient: AxiosInstance = axios.create({
  baseURL,
  timeout: 10000,
});

/**
 * Sets the response interceptors. Normalizes the response and handles certain error cases.
 */
axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
  if (error.response) {
    if (error.response.status >= 500) {
      Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    } else {
      throw new ApiError(error.response.status, error.message, error.response.data.data);
    }
  } else {
    Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.NETWORK_ERROR');
  }
  return Promise.reject(error);
});

export const AUTH_CLIENT = axiosClient;

import Parseable from '@/misc/Parseable';

export default class BillingDetails extends Parseable {
    public id!: string;
    public iban!: string;
    public bic!: string;

    public static parseFromObject(object: any): BillingDetails {
        const company = new BillingDetails();
        Object.assign(company, object);
        return company;
    }

    public parseToObject(): Partial<BillingDetails> {
        return { ...this };
    }
}

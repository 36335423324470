import { AxiosResponse } from 'axios';
import { AUTH_CLIENT } from '../misc/AuthClient';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

export default class AuthRepository {
  public static login(payload: { email: string, password: string }): Promise<AxiosResponse> {
    return AUTH_CLIENT.post('auth/login', payload);
  }

  public static initiatePasswordReset(email: string): Promise<AxiosResponse> {
    return AUTH_CLIENT.post('/auth/reset-password', {
      email: email
     });
  }

  public static resetPassword(token: string, password: string): Promise<AxiosResponse> {
    return AUTH_CLIENT.put('/auth/reset-password', {
      token: token,
      password: password
    });
  }

  public static verifyEmailWithToken(token: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.post('api/account/confirm-email-change', {
      token: token
    });
  }
}
